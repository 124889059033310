
import {defineComponent, onMounted, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "activate",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const loading = ref<boolean>(false);
    const submitButton = ref<HTMLElement | null>(null);
    const query = route.query

    const checkHash = () => {
      loading.value = true

      if (!query.hash) {
        router.push({ name: "apps-users-listing" });
        return false;
      }

      query.action = "activate"
      store.dispatch(Actions.CHECK_HASH, query)
          .then(() => {
            loading.value = false
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    onMounted(() => {
      checkHash()
    });

    //Create form validation object
    const activate = Yup.object().shape({
      password: Yup.string().min(4).required().label("Password"),
    });

    //Form submit function
    const onSubmit = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      values = {
        hash: query.hash,
        password: values.password
      }

      // Dummy delay
      setTimeout(() => {
        // Send activate request
        store
          .dispatch(Actions.ACTIVATE, values)
          .then(() => {
            Swal.fire({
              text: "All is cool! Now you submit this form",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully activate
              router.push({ name: "apps-users-listing" });
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmit,
      activate,
      submitButton,
      loading,
    };
  },
});
